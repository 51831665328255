import React from 'react';
import { MdErrorOutline } from 'react-icons/md';

import tw from 'twin.macro';

import { Layout } from '@layouts';
import { Container, Heading, Subheading } from '@components';

const NotFoundPage = () => (
  <Layout title="404: Nie znaleziono">
    <Container css={tw`text-center py-40 md:py-48`}>
      <Heading as="h1" css={tw`mb-5`}>
        <MdErrorOutline css={tw`text-accent text-6xl mb-5 mx-auto`} />
        Nie znaleziono
      </Heading>
      <Subheading as="h2">Strona, którą szukasz nie istnieje!</Subheading>
    </Container>
  </Layout>
);

export default NotFoundPage;
